<template>
  <!--
    The view for the Weather, DetailsTable, geoMap and SpotVisualisation-components.
    Used to display more detailed informations about the installation.
  -->
  <div class="installationDetail_Detail">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <DocumentationStaticResourceUploader
        v-if="hasDocumentUploader"
        class="mb-3"
        :installation-id="installationId"
      />
      <div class="row">
        <div class="col-12 col-md-4">
          <div class="card">
            <Portlet
              :title="$t('installationDetailDetailView.details')"
              icon="info-circle"
            >
              <DetailsTable
                :installation-object="installationObject"
                @reloadInstallation="getInstallation()"
              />
            </Portlet>
          </div>
        </div>
        <div
          class="col-12 col-md-8"
        >
          <div
            v-if="validLocation"
            class="card"
          >
            <Weather
              :latitude="installationObject.latitude"
              :longitude="installationObject.longitude"
              :limit="3"
            />
          </div>
          <div
            class="card"
          >
            <GeoMap
              :id="installationObject.id"
              :latitude="installationObject.latitude"
              :longitude="installationObject.longitude"
              @updateDetails="updateDetails"
            />
          </div>
          <div
            v-if="showSpotVisualisation"
            class="card"
          >
            <Portlet
              :title="$t('installationDetailDetailView.spotVisualisation')"
              icon="camera"
            >
              <SpotVisualisation :spot="defaultSpot" />
            </Portlet>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "InstallationDetailDetail",
  components: {
    GeoMap: () => import('@/components/GeoMap.vue'),
    Weather: () => import('@/components/Weather.vue'),
    DetailsTable: () => import('@/components/Installation/DetailsTable.vue'),
    SpotVisualisation: () => import('@/components/SpotVisualisation/SpotVisualisation.vue'),
    DocumentationStaticResourceUploader: () => import('@/components/InstallationDocumentation/DocumentationStaticResourceUploader.vue'),
  },
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      installationObject: null,
      loading: true,
      defaultSpot: {
        startScene: 'tunnel first gen',
        rFIDOpen: '',
        people: 1,
        border: true,
        slopes: 1,
        goal: true,
        jump: '',
        cameraOne: 0,
        cameraTwo: 0,
        cameraThree: 0,
        startTrigger: 'Alge',
        startLoudspeaker: 'yes',
        startCamera: 'Motobotix',
        startDisplay: 'DSA115',
        rFID: 'Skidata Easygate'
      }
    }
  },
  metaInfo () {
    return {
      title: this.$t('installationDetailDetailView.details')
    }
  },
  computed: {
    validLocation () {
      if (this.installationObject.latitude && this.installationObject.longitude) {
        return true;
      }
      return false;
    },
    showSpotVisualisation () {
      // TODO implement the working spotVisualisation first, then update this computed-property to allow to show the visualisation
      return false;
    },
    hasDocumentUploader () {
      if (!this.installationObject || !this.installationObject.installationType) {
        return false;
      }
      let type = this.installationObject.installationType.trim().toLowerCase();
      let allowedTypes = [ 'photopoint', 'terminal', 'speedcheck', 'phototrap', 'photostart', 'skimovie' ];
      return allowedTypes.includes(type);
    }
  },
  created () {
    this.getInstallation();
  },
  methods: {
    getInstallation () {
      this.loading = true;
      this.axios.get('/Installation/Get?id=' + this.installationId)
        .then((response) => {
          this.installationObject = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateDetails () {
      this.getInstallation();
    }
  }
}
</script>